import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";
import { BgImage } from "gbimage-bridge";
import { Helmet } from "react-helmet";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Col, Row, Container, Button } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import SafeHtmlParser from "../components/safe-html-parser";
import Wave from "../images/wave.svg";

const AboutPage = () => {
	const tl = useRef();
	const {
		wpAboutUs: { aboutUsFields, seoFields },
		site,
	} = useStaticQuery(graphql`
		query {
			wpAboutUs {
				aboutUsFields {
					heroSection {
						title
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					aboutUsSection {
						title
						body
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					getInTouch {
						title
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					image {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}

								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const { heroSection, aboutUsSection, getInTouch } = aboutUsFields;

	const siteUrl = site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About",
				item: {
					url: `${siteUrl}/about`,
					id: `${siteUrl}/about`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<BgImage
							className=" position-relative"
							image={getImage(
								heroSection.image.localFile.childImageSharp.gatsbyImageData
							)}
						>
							<div
								style={{ opacity: 0.4 }}
								className="w-100 h-100 bg-black position-absolute start-0 top-0"
							></div>
							<Container
								style={{ zIndex: "2" }}
								className="h-100 position-relative py-8 py-lg-10"
							>
								<Row className="align-items-center justify-content-center text-center h-100">
									<Col xs={12} lg={8}>
										<h1
											style={{ lineHeight: "90%" }}
											className=" display-1 Alex-Brush   text-white"
											id="home-hero-headline"
										>
											{heroSection.title}
										</h1>
									</Col>
								</Row>
							</Container>
							<img
								src={Wave}
								alt=""
								style={{
									width: "100%",
									bottom: "-4px",
								}}
								className="position-absolute  start-0"
							/>
						</BgImage>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row className="align-items-center">
								<Col lg={{ span: 6, order: "last" }}>
									<GatsbyImage
										className=" w-100"
										image={
											aboutUsSection.image.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={aboutUsSection.image.altText}
									/>
								</Col>
								<Col lg={6}>
									<div className="px-2 px-md-0">
										<h2 className="mt-4 mb-3 Garamond-Med display-5">
											{aboutUsSection.title}
										</h2>
										<div style={{ lineBreak: "normal" }}>
											<SafeHtmlParser htmlContent={aboutUsSection.body} />
										</div>

										<Link
											to="/contact"
											className="fs-4 primary-link Garamond-Med non-capital mt-4"
										>
											{getInTouch.title}
										</Link>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default AboutPage;
